import "./Home.scss";

import React, { useEffect, useState } from "react";

import RevFAmplifierSuitePage from "../Products/Rev F";
import EcocideAmplifierSuitePage from "../Products/EcocideAmp"
import { Helmet } from 'react-helmet';

const Home: React.FC = () => {
  return <div>
    <RevFAmplifierSuitePage useHelmet={false} />
    {/* <Helmet>
      <title>WaveMind Audio | Specialist in Guitar Software and Plugins</title>
      <link rel="canonical" href="https://wavemind.net/" />
    </Helmet> */}
  </div>
};

export default Home;
