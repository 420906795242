import "./mainbanner.scss"
import { Box, Grid, Button, CircularProgress, Typography, Skeleton, IconButton, Tooltip } from "@mui/material";

import ampPic from "../../../static/images/revf/rev_f_software_small.webp"
import ampvid from "../../../static/images/revf/rev_f_video_desktop.mp4"
import ampvidMobile from "../../../static/images/revf/rev_f_video_mobile.mp4"

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import InfoIcon from '@mui/icons-material/Info';

import { useEffect, useState } from "react";
import BuyButton from "../../BuyButton";

const Image = () => {
  return <img fetchpriority="high" alt="Amp Suite Rev F" src={ampPic} width="100%" style={{ maxWidth: "800px" }}></img>
}

const MainBanner = ({ price, product, onTrial }) => {

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const [animationDone, setAnimationDone] = useState(true);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < window.innerHeight
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  return (
    <Box
      sx={{
        backgroundSize: "cover",
        position: "relative",
        p: 0,
        m: 0,
        flex: 100,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        display: { xs: "block", md: "flex" },
        flexDirection: "column",
        minHeight: "100vh",
        // maxHeight: "calc(100vh)",
      }}
    >
      {/* <div
        style={{
          position: "fixed",
          width: "100vw",
          // height: "200vh",
          // background: "linear-gradient(90deg, #20A4F3 0%, #2EC4B6 100%)",
          background: "black",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          // filter: "brightness(50%)",
          justifyContent: "center",
          top: 0,
          left: 0,
          zIndex: -99,

        }}
      > */}
      <video style={{
        width: "100%",
        height: "100%",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        position: "absolute",
        zIndex: -1,
        objectFit: "cover",
        backgroundColor: "black",
        pointerEvents: "none"
      }} muted defaultMuted autoPlay playsInline oncontextmenu="return false;" preload="auto" onEnded={() => setAnimationDone(true)}>
        <source loading="lazy" fetchpriority="low" src={state.mobileView ? ampvidMobile : ampvid} type="video/mp4"></source>
      </video>
      {/* </div> */}

      <Box sx={{
        position: "absolute",
        fontSize: 50,
        color: "white",
        href: "#ampBanner",
        variant: "outlined",
        bottom: { xs: 180, md: 100 },
        left: 0,
        right: 0,
        mr: "auto",
        ml: "auto",

      }}
        className={animationDone ? "fadeOut" : "fadeIn"}
      >
        <IconButton
          href="#ampBanner"
          sx={{
            color: "white",
          }}
        >
          <ExpandMoreIcon sx={{ fontSize: 50 }} />
        </IconButton>
      </Box>

      <Grid container justifyContent={"center"} className={animationDone ? "fadeIn" : "fadeOut"}>
        <Grid xs={12} md={8} sx={{ background: "none", p: 1, borderRadius: 10, }}>
          <Image />
          {/* <Box sx={{ minHeight: "100vh" }} /> */}
          <Typography
            variant="h1"
            fontSize={30}
            sx={{ fontSize: 30, }}
          >
            Amp Suite - Rev F
          </Typography>
          <Typography
            // fontFamily={"pirulen"}
            style={{
              fontSize: state.mobileView ? "20px" : "30px",
              // fontWeight: 500,
            }}
          >
            Classic tones from the 90's
          </Typography>

          <Typography sx={{ display: "flex", mt: 2, fontWeight: "bold", fontSize: { xs: "30px", md: "40px" }, justifyContent: "center", alignItems: "center", gap: 2 }}>
            {price}
            {price === undefined && <Skeleton variant="text" width={210} />}
            <Tooltip title="For EU customers, VAT will be added on checkout."><IconButton sx={{ color: "rgb(249, 245, 238)", width: "24px", height: "24px" }}><InfoIcon /></IconButton></Tooltip>
          </Typography>
          <Box sx={{ mt: 3, columnGap: 5, rowGap: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "center", alignItems: "center" }}>
            <Button
              sx={{ width: { xs: "75%", md: "150px" } }}
              variant="outlined"
              onClick={() => {
                const element = document.getElementById("ampBanner");
                if (element) {
                  let y = element?.getBoundingClientRect()?.top + window.pageYOffset - 64;
                  window.scrollTo({ top: y, behavior: 'smooth' });
                }
              }}
            >
              Learn More
            </Button>
            <Button
              variant="outlined"
              onClick={onTrial}
              sx={{ width: { xs: "75%", md: "150px" } }}
            >
              Free Trial
            </Button>
            <BuyButton color="primary" variant="contained" product={product} sx={{ width: { xs: "75%", md: "150px" } }} />
          </Box>
        </Grid>
      </Grid>
    </Box >
  );
};

export default MainBanner;
